import ApiService from "@/core/services/ApiService";
import { processErrors } from "./errorsProcessing";

export const url = "api/v1/CPT";

export async function searchCPTs(request) {
  return await ApiService.post(url + "/search", request as never)
    .then((res) => {
      const data = res.data;
      return data;
    })
    .catch(({ response }) => {
      processErrors(response);
    });
}

export async function searchDictCPTs(request) {
  return await ApiService.post(url + "/searchDict", request as never)
    .then((res) => {
      const data = res.data;
      return data;
    })
    .catch(({ response }) => {
      processErrors(response);
    });
}

export async function searchByCodeCPTs(request) {
  return await ApiService.post(url + "/searchByCode", request as never)
    .then((res) => {
      const data = res.data;
      return data;
    })
    .catch(({ response }) => {
      processErrors(response);
    });
}



export async function addCPT(CPT) {
  return await ApiService.post(url, CPT as never).catch(({ response }) => {
    processErrors(response);
  });
}

export async function getCPT(CPTId) {
  return await ApiService.get(url + "/" + CPTId)
    .then((res) => {
      const item = res.data;
      return item;
    })
    .catch(({ response }) => {
      processErrors(response);
    });
}

export async function updateCPT(CPT) {
  if (CPT) {
    return await ApiService.put(url + "/" + CPT.id, CPT as never)
      .then((res) => {
        return res;
      })
      .catch(({ response }) => {
        processErrors(response);
      });
  }
}

export async function deleteCPT(CPT) {
  if (CPT) {
    await ApiService.delete(url + "/" + CPT?.id)
      .then()
      .catch(({ response }) => {
        processErrors(response);
      });
  }
}
