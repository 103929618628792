
  import Multiselect from "@vueform/multiselect";
  import { defineComponent, onMounted, ref, reactive, nextTick, watch } from "vue";
  import { getOrganization } from "@/core/services/JwtService";
  import { useRouter } from "vue-router";
  import State from "@/components/ABilling/StateComponent.vue";
  import Country from "@/components/ABilling/CounrtyComponent.vue";
  import PhoneNumberComponent from "@/components/ABilling/PhoneNumberComponent.vue";
  import {
    addInsuranceCompany,
    getInsuranceCompany,
    searchInsuranceCompanys,
    updateInsuranceCompany,
  } from "@/api/code-master-insurance-company.api";
  import { helpers, required, requiredIf } from "@vuelidate/validators";
  import { getRehab } from "@/core/services/JwtService";
  import useVuelidate from "@vuelidate/core";
  import useFilters from "@/modules/common/useFilters";
  import { searchDictCPTs } from "@/api/code-master-cpt.api";
  import { searchDictHCPCSs } from "@/api/code-master-hcpcs.api";
  import { getDictionary } from "@/api/dictionaries.api";
  import Swal from "sweetalert2/dist/sweetalert2.js";


  export interface Data {
    items: [];
    cptList: { id: string; disabled: boolean }[];
    hcpcsList: { id: string; disabled: boolean }[];
    previousInsuranceCompany: any;
    displayInactive: boolean;
    selectedItem: any;
    isEditMode: boolean;
    orderBy: string;
    IsAdvancedOrderBy: boolean;
    isDecr: boolean;
    isDirty: boolean;
    keyword: string;
    dict: {
      payerTypes: { code: string; description: string; }[];
      networkTypes: string[];
    };
  }
  export default defineComponent({
    name: "InsuranceCompanyAddPage",
    props: ["insuranceCompanyId"],
    components: { State, Country, Multiselect, PhoneNumberComponent },
    beforeRouteLeave(to, from, next) {
      const component = (this.data as any);
      if (component.isDirty ) {
        let text = "Are you sure you want to leave without saving changes?";
        Swal.fire({
          title: text,
          showDenyButton: true,
          showCancelButton: true,
          confirmButtonText: "Yes",
          denyButtonText: "No",
          allowOutsideClick: false,
          customClass: {
            actions: "my-actions",
            cancelButton: "order-1 right-gap",
            confirmButton: "order-2",
            denyButton: "order-3",
          },
        }).then((result) => {
          if (result.isConfirmed) {
            next();
          } else if (result.isDismissed) {
            next(false);
          }
        });
      }
      else {
        next();
      }
    },
    setup(props, ctx) {
      let organizationId = ref<string | null>("");
      const router = useRouter();

      const { orderList } = useFilters();
      orderList.value = [
        {
          name: " ",
          key: " ",
          isFilter: false,
          keyword: null,
        },
        {
          name: "Ins Co",
          key: "name",
          isFilter: true,
          keyword: null,
        },
        {
          name: "Payer Id",
          key: "payerId",
          isFilter: true,
          keyword: null,
        },
        {
          name: "In Use",
          key: "inUse",
          isFilter: true,
          keyword: null,
        },
        {
          name: "Active",
          key: "isActive",
          isFilter: true,
          keyword: null,
        },
      ];

      let data = reactive<Data>({
        items: [],
        cptList: [],
        hcpcsList: [],
        previousInsuranceCompany: {},
        displayInactive: false,
        selectedItem: undefined,
        isEditMode: true,
        keyword: "",
        orderBy: "payerId",
        IsAdvancedOrderBy: false,
        isDecr: false,
        isDirty: false,
        dict: {
          payerTypes: [],
          networkTypes: ["In Network", "Out Of Network"]
        }
      });


      const isPhone = helpers.regex(/^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/im); // eslint-disable-line

      const InsuranceCompanyRules = {
        selectedItem: {
          name: { required: helpers.withMessage("Required", required) },
          officeNumber: {
            isPhone: helpers.withMessage("Phone number is invalid", isPhone)
          },
          otherNumber: {
            isPhone: helpers.withMessage("Phone number is invalid", isPhone)
          },
          fax: {
            isPhone: helpers.withMessage("Fax number is invalid", isPhone)
          }
        },
      };

      let v$ = useVuelidate(InsuranceCompanyRules, data as never);

      onMounted(async () => {
        organizationId.value = getOrganization();
        data.selectedItem = {
          payerType: null,
          network: null,
          state: null,
          country: "1",
          cptPayerItems: [],
          hcpcsPayerItems: []
        };

        data.cptList = await searchDictCPTs({ search: "" });
        data.hcpcsList = await searchDictHCPCSs({ search: "" });
        data.dict.payerTypes = await getDictionary(17, "");
        await getAll();

        getCPT();
        getHCPCS();
        if(props.insuranceCompanyId){

          var company = await getInsuranceCompany(props.insuranceCompanyId) as any;
          data.selectedItem = company;
          data.isEditMode = true;
        }

        if (data.selectedItem) {
          data.previousInsuranceCompany = JSON.parse(JSON.stringify(data.selectedItem));
        } else {
          data.previousInsuranceCompany = {};
        }
      });

      watch(
        () => data.selectedItem,
        (currentValue, oldValue) => {
          ctx.emit("insuranceCompanyInfoChanged", data.selectedItem);
          var emptyValue = {};
          if (
            JSON.stringify(data.previousInsuranceCompany) != JSON.stringify(emptyValue) &&
            data.selectedItem &&
            JSON.stringify(data.selectedItem) != JSON.stringify(data.previousInsuranceCompany)
          ) {
            data.isDirty = true;
          }
        },
        { deep: true }
      );

      async function getAll() {
        let order = data.orderBy;
        if (data.isDecr) {
          order = order + " Desc";
        }
        let request = {
          keyword: data.keyword,
          orderBy: [order],
          displayInactive: data.displayInactive,
        };

        const res = await searchInsuranceCompanys(request);
        data.items = res.data;

        v$.value.$reset();
      }

      async function selectItem(item) {
        //TODO DEBT
        data.selectedItem = { country: item.country };
        await nextTick();

        data.selectedItem = item;
        getCPT();
        getHCPCS();
      }

      async function addItem() {
        const result = await v$.value.selectedItem.$validate();
        if (result) {
          data.selectedItem.RehabId = getRehab();
          await addInsuranceCompany(data.selectedItem);
          data.isDirty = false;
          clear();
        }
      }

      async function updateItem() {
        const result = await v$.value.selectedItem.$validate();
        if (result) {
          await updateInsuranceCompany(data.selectedItem);
          data.isDirty = false;
          clear();
          //await getAll();
        }
      }

      async function addMode() {
        data.selectedItem = {
          payerType: null,
          network: null,
          state: null,
          country: "1"
        };
        data.isEditMode = true;
      }

      async function selectFilter(header) {
        if (data.orderBy == header.key) {
          data.isDecr = !data.isDecr;
        } else {
          data.isDecr = false;
        }
        data.orderBy = header.key;

        await getAll();
      }

      function getSortInfo(key) {
        if (data.orderBy == key) return true;
        return false;
      }

      function edit() {
        data.isEditMode = true;
      }

      async function clear() {
        router.push({ path:"/Settings/insuranceCompanyPage"});
      }

      async function getCPT() {
        await nextTick();
        let res = data.cptList;
        res.forEach((item) => {
          item.disabled = false;
        });

        const selectedCodes = data.selectedItem.cptPayerItems.map((item) => {
          return item.cptId;
        });

        res = res.filter((item) => {
          return selectedCodes.includes(item.id);
        });

        res.forEach((item) => {
          item.disabled = true;
        });
      }

      async function getHCPCS() {
        await nextTick();
        let res = data.hcpcsList;
        res.forEach((item) => {
          item.disabled = false;
        });

        const selectedCodes = data.selectedItem.hcpcsPayerItems.map((item) => {
          return item.hcpcsId;
        });

        res = res.filter((item) => {
          return selectedCodes.includes(item.id);
        });

        res.forEach((item) => {
          item.disabled = true;
        });
      }

      return {
        organizationId,
        selectItem,
        updateItem,
        addItem,
        addMode,
        getCPT,
        getHCPCS,
        selectFilter,
        getSortInfo,
        orderList,
        edit,
        getAll,
        clear,
        v$,
        data,
      };
    },
  });
